<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="用户信息">
              <a-input
                placeholder="请输入姓名/身份证号/手机号"
                v-model="search.name"
              />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="注册方式">
              <a-select
                v-model="search.sourceType"
                allowClear
                placeholder="请选择"
              >
                <a-select-option :value="0">个人注册</a-select-option>
                <a-select-option :value="1">批量导入</a-select-option>
                <a-select-option :value="2">后台创建</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4" :style="{ textAlign: 'left' }">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <pxkeji-btn
          name="加入用户"
          class="pxkeji_basic_btn"
          @click="chosestudent()"
          type="primary"
          :roles="['用户组-成员维护']"
        />
        <pxkeji-btn
          name="批量移除"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          @click="batchRemove"
          type="danger"
          :roles="['用户组-成员维护']"
        />
      </div>
    </div>

    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          onChange: onRowChange,
          columnWidth: 40,
          selectedRowKeys: selectRows,
        }"
        :locale="{ emptyText: '暂无数据' }"
      >
        <template slot="sex" slot-scope="sex">
          {{ sex == 1 ? "男" : sex == 2 ? "女" : "" }}
        </template>
        <template slot="sourceType" slot-scope="sourceType">
          {{
            sourceType == 0
              ? "个人注册"
              : sourceType == 1
              ? "批量导入"
              : "后台创建"
          }}
        </template>
        <template slot="action" slot-scope="action">
          <a-popconfirm @confirm="removeInfo(action.id)">
            <div slot="title">是否确认移除此用户？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="移除"
              size="small"
              type="danger"
              :roles="['用户组-成员维护']"
            />
          </a-popconfirm>
        </template>
      </a-table>
    </div>

    <a-modal
      title="选择学员"
      v-model="student.visible"
      width="80%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <ChooseStudent
        :id="student.groupId"
        type="userGroup"
        @callbackMethod="saveSelectStudent"
      />
    </a-modal>
  </div>
</template>
<script>
import ChooseStudent from "../element/ChooseStudent.vue";
const columns = [
  {
    dataIndex: "fullName",
    align: "center",
    title: "姓名",
  },
  {
    dataIndex: "sex",
    align: "center",
    title: "性别",
    width: 80,
    scopedSlots: { customRender: "sex" },
  },
  {
    dataIndex: "telephone",
    align: "center",
    title: "手机号",
  },
  {
    dataIndex: "idCard",
    align: "center",
    title: "身份证号",
  },
  {
    dataIndex: "createTime",
    align: "center",
    title: "添加时间",
    width: 180,
  },
  {
    dataIndex: "sourceType",
    align: "center",
    title: "注册方式",
    width: 120,
    scopedSlots: { customRender: "sourceType" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "UserGroupMember",
  props: {
    id: null,
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
      },
      selectRows: [], //选中项编号
      isloading: false,
      search: {
        //搜索条件
        name: "",
      },
      student: {
        //选择学员
        groupId: "",
        visible: false,
      },
    };
  },
  components: {
    ChooseStudent,
  },
  mounted() {
    _this = this;
    _this.student.groupId = _this.id;
    console.log(_this.id);
    if (_this.student.groupId) {
      _this.GetList();
    }
  },
  methods: {
    chosestudent() {
      _this.student.visible = true;
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.clearRows();
      _this.$api.usergroup
        .getGroupMemberPageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.id,
          _this.search.name,
          _this.search.sourceType
        )
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      //table 多选
      _this.selectRows = selectedRowKeys;
    },
    clearRows() {
      //清空选中项
      _this.selectRows = [];
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    /*批量 */
    batchRemove() {
      _this.$confirm({
        title: "提示",
        content: `您确定要移除所选的用户吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.removeInfo(_this.selectRows.join(","));
        },
        onCancel() {},
      });
    },
    saveSelectStudent(datas) {
      if (datas && datas.length > 0) {
        _this.$api.usergroup
          .addUsers(datas, _this.id)
          .then((res) => {
            if (res.errorCode == this.$msg.responseCode.success) {
              _this.$message.success("操作成功");
              _this.student.visible = false;
              _this.GetList();
            } else {
              _this.$message.error(res.errorMsg);
            }
          })
          .catch(() => {
            _this.isloading = false;
          });
      } else {
        _this.student.visible = false;
      }
    },
    removeInfo(ids) {
      _this.isloading = true;
      _this.$api.usergroup
        .removeUsers(ids, _this.id)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

