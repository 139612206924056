<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form"> </a-form>
      <div>
        <pxkeji-btn
          name="添加"
          class="pxkeji_basic_btn"
          @click="showInfo({ id: 0 })"
          type="primary"
          :roles="['用户组-添加']"
        />
      </div>
    </div>
    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <template slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑"
            @click="showInfo(action)"
            size="small"
            type="primary"
            :roles="['用户组-修改']"
          />
          <pxkeji-btn
            name="成员管理"
            @click="gotomember(action.id)"
            size="small"
            type="primary"
            :roles="['用户组-成员维护']"
          />
          <a-popconfirm
            title="是否确认删除此用户组？"
            @confirm="delInfo(action.id)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除"
              size="small"
              type="danger"
              :roles="['用户组-删除']"
            />
          </a-popconfirm>
        </template>
      </a-table>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      width="800px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <UserGroupInfo @callbackMethod="save" :infos="addedit.info" />
    </a-modal>
    <a-modal
      title="用户组成员"
      v-model="member.visible"
      width="80%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <UserGroupMember @callbackMethod="closeMember" :id="member.id" />
    </a-modal>
  </div>
</template>
<script>
import UserGroupInfo from "./usergroupInfo.vue"; //新增编辑
import UserGroupMember from "./usergroupmember.vue"; //成员列表

const columns = [
  {
    dataIndex: "name",
    align: "center",
    title: "名称",
  },
  {
    key: "action",
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "UserGroupList",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 292,
      },
      isloading: false,
      addedit: {
        title: "",
        info: {},
        visible: false,
      },
      member: {
        visible: false,
        id: 0,
      },
    };
  },
  components: {
    UserGroupInfo,
    UserGroupMember,
  },
  mounted() {
    _this = this;
    _this.GetList();
  },
  methods: {
    gotomember(id) {
      _this.member.id = id;
      _this.member.visible = true;
    },
    closeMember() {
      _this.member.visible = false;
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.$api.usergroup
        .getAll()
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.GetList();
    },
    showInfo(info) {
      //打开弹出框
      _this.addedit.title = info.id ? "编辑" : "新增";
      _this.addedit.info = info;
      _this.addedit.visible = true;
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      _this.$api.usergroup
        .removeById(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    save() {
      _this.addedit.visible = false;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

