<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="用户信息">
              <a-input
                placeholder="请输入姓名/身份证号/手机号"
                v-model="search.name"
              />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="注册方式">
              <a-select
                v-model="search.sourceType"
                allowClear
                placeholder="请选择"
              >
                <a-select-option :value="0">个人注册</a-select-option>
                <a-select-option :value="1">批量导入</a-select-option>
                <a-select-option :value="2">后台创建</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4" :style="{ textAlign: 'left' }">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table
      rowKey="id"
      size="small"
      bordered
      :columns="list.columns"
      :pagination="list.pagination"
      @change="onPageChange"
      :dataSource="list.datas"
      :loading="isloading"
      :rowSelection="{
        type: 'checkbox',
        onChange: onRowChange,
        columnWidth: 40,
        selectedRowKeys: selectRows,
      }"
      :locale="{ emptyText: '暂无数据' }"
    >
      <template slot="sex" slot-scope="sex">
        {{ sex == 1 ? "男" : sex == 2 ? "女" : "" }}
      </template>
      <template slot="sourceType" slot-scope="sourceType">
        {{
          sourceType == 0
            ? "个人注册"
            : sourceType == 1
            ? "批量导入"
            : "后台创建"
        }}
      </template>
    </a-table>
    <a-form-item
      :wrapper-col="{ span: 4, offset: 10 }"
      style="margin-top: 12px"
    >
      <pxkeji-btn name="确定" type="primary" @click="confirmSelect" />
      <pxkeji-btn
        name="取消"
        type="default"
        @click="cancelClick"
        class="cancelBtn"
      />
    </a-form-item>
  </div>
</template>
<script>
const columns = [
  {
    dataIndex: "fullName",
    align: "center",
    title: "姓名",
  },
  {
    dataIndex: "sex",
    align: "center",
    title: "性别",
    width: 80,
    scopedSlots: { customRender: "sex" },
  },
  {
    dataIndex: "telephone",
    align: "center",
    title: "手机号",
  },
  {
    dataIndex: "idCard",
    align: "center",
    title: "身份证号",
  },
  {
    dataIndex: "createTime",
    align: "center",
    title: "添加时间",
    width: 180,
  },
  {
    dataIndex: "sourceType",
    align: "center",
    title: "注册方式",
    width: 120,
    scopedSlots: { customRender: "sourceType" },
  },
];

let _this;
export default {
  name: "ChooseStudent",
  props: {
    id: String,
    type: String,
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
      },
      search: {
        //搜索条件
        name: "",
      },
      selectRows: [], //选中项编号
      isloading: false,
    };
  },
  mounted() {
    _this = this;
    _this.GetList();
  },
  methods: {
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    confirmSelect() {
      if (_this.selectRows.length == 0) {
        _this.$message.error("请选择用户");
      }
      _this.$emit("callbackMethod", _this.selectRows);
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.clearRows();
      let searchData = { ..._this.search };
      if (_this.type == "userGroup") {
        searchData.notInGroupId = _this.id;
      }
      _this.$api.student
        .getStudentPageListNoAuth(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          searchData
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      //table 多选
      _this.selectRows = selectedRowKeys;
    },
    clearRows() {
      //清空选中项
      _this.selectRows = [];
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.nextBtn {
  margin-left: 20px;
}
</style>

