<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="userGroupForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="名称" prop="name">
          <a-input placeholder="请输入" v-model="info.name"></a-input>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;
export default {
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      info: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      isloading: false,
      loadingTip: "加载中...",
    };
  },
  mounted() {
    _this = this;
    if (_this.infos.id) {
      //编辑
      _this.getInfo();
    }
  },
  methods: {
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    getInfo() {
      _this.info = _this.infos;
    },
    handleSubmit() {
      _this.$refs.userGroupForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let data = { ..._this.info };
          if (_this.infos.id) {
            data.id = _this.infos.id;
          }
          //新增
          _this.$api.usergroup
            .saveOrUpdate(data)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
.anticon-plus-circle {
  margin-left: 10px !important;
  cursor: pointer;
}
.anticon-minus-circle {
  margin-left: 10px !important;
  cursor: pointer;
}
</style>